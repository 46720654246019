<template>
  <div style="padding: 20px">
    <div style="color: #999;font-size: 1.3em;text-align: center;">
      观影清单 - Redcker
    </div>
    <el-row :gutter="20">
      <el-col :md="6" :xs="12" v-for="(movie,index) in movies" :key="index" class="card-col">
        <a :href="movie.url" target="_blank">
          <el-card :body-style="{ padding: '0px',background:'#333',color:'#999',height:'100%' }" shadow="never">
            <el-image :style="{height: '400px'}" v-if="!isMobile"
                      :src="movie.cover"
                      class="image" lazy/>
            <el-image :style="{height:'260px'}" v-else
                      :src="movie.cover"
                      class="image" lazy>
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <div style="padding: 14px;">
              <span>{{ movie.name }}</span>
            </div>
          </el-card>
        </a>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import movies from "@/assets/movies";
export default {
  name: "index",
  data() {
    return {
      isMobile: false,
      movies: movies
    }
  },
  mounted() {
    this.checkDevice()
  },
  methods: {
    checkDevice() {
      this.isMobile = /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
}
</script>

<style scoped>
.image {
  display: block;
}

.card-col {
  margin-top: 20px;
}

.image-pc:hover {
  transform: scale(.97);
  transition: transform .4s;
}

.search-input {
  max-width: 50%;
  margin: 0 auto 20px;
  display: block;
}

.el-card {
  border: none;
}

a {
  text-decoration: none;
}
</style>