const movies = [{
    'url': 'https://movie.douban.com/subject/1293181/',
    'name': '惊魂记',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p1021883305.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1417598/',
    'name': '电锯惊魂',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p726839485.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1292225/',
    'name': '闪灵',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2461253191.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3011308/',
    'name': '孤儿怨',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p1592298962.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1306809/',
    'name': '生化危机',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2174120073.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1301459/',
    'name': '小岛惊魂',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p492298664.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3804629/',
    'name': '招魂',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p1936517673.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1945330/',
    'name': '迷雾',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p1970406441.jpg'
}, {
    'url': 'https://movie.douban.com/subject/24860563/',
    'name': '招魂2',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2360924286.jpg'
}, {
    'url': 'https://movie.douban.com/subject/26688480/',
    'name': '逃出绝命镇',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2508604641.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1466007/',
    'name': '寂静岭',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p554634907.jpg'
}, {
    'url': 'https://movie.douban.com/subject/2132473/',
    'name': '僵尸世界大战',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2119276553.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1763134/',
    'name': '汉江怪物',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2512336853.jpg'
}, {
    'url': 'https://movie.douban.com/subject/26339213/',
    'name': '无名女尸',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2407543903.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3143676/',
    'name': '林中小屋',
    'cover': 'https://img3.doubanio.com/view/photo/s_ratio_poster/public/p1323381020.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1293755/',
    'name': '驱魔人',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p1495239725.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1306808/',
    'name': '咒怨',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p2410260517.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1309088/',
    'name': '活死人黎明',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2493796125.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1307696/',
    'name': '午夜凶铃',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p1324520469.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3259993/',
    'name': '丧尸乐园',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2210458082.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1306421/',
    'name': '惊变28天',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p2191905718.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1305114/',
    'name': '山村老尸',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2554609596.jpg'
}, {
    'url': 'https://movie.douban.com/subject/2059276/',
    'name': '哥斯拉',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p2172120157.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3792868/',
    'name': '亡灵',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p1567870772.jpg'
}, {
    'url': 'https://movie.douban.com/subject/35477218/',
    'name': '怪奇宅',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2670777611.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1297996/',
    'name': '活死人之夜',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2182915725.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1758487/',
    'name': '惊变28周',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p2191902838.jpg'
}, {
    'url': 'https://movie.douban.com/subject/26945085/',
    'name': '昆池岩',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2513360824.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1297100/',
    'name': '活死人归来',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2209372161.jpg'
}, {
    'url': 'https://movie.douban.com/subject/2334904/',
    'name': '禁闭岛',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p450262388.jpg'
}, {
    'url': 'https://movie.douban.com/subject/21360417/',
    'name': '恐怖直播',
    'cover': 'https://img1.doubanio.com/view/photo/s_ratio_poster/public/p2054744538.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3011051/',
    'name': '恐怖游轮',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p462470694.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1300555/',
    'name': '回到未来',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p475872903.jpg'
}, {
    'url': 'https://movie.douban.com/subject/26266893/',
    'name': '流浪地球',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2545472803.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1292523/',
    'name': '侏罗纪公园',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2076443176.jpg'
}, {
    'url': 'https://movie.douban.com/subject/10440138/',
    'name': '侏罗纪世界',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2246217861.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3793023/',
    'name': '三傻大闹宝莱坞',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p579729551.jpg'
}, {
    'url': 'https://movie.douban.com/subject/26752088/',
    'name': '我不是药神',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2561305376.jpg'
}, {
    'url': 'https://movie.douban.com/subject/1292720/',
    'name': '阿甘正传',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p2372307693.jpg'
}, {
    'url': 'https://movie.douban.com/subject/3793023/',
    'name': '三傻大闹宝莱坞',
    'cover': 'https://img2.doubanio.com/view/photo/s_ratio_poster/public/p579729551.jpg'
}, {
    'url': 'https://movie.douban.com/subject/30234315/',
    'name': '摄影机不要停!',
    'cover': 'https://img9.doubanio.com/view/photo/s_ratio_poster/public/p2541824676.jpg'
},
]


export default movies